import React from 'react';

function TooltipImage({ src, alt, tooltipText, style }) {
  return (
    <div className="tooltip">
      <img src={src} alt={alt} style={style} />
      <span className="tooltiptext">{tooltipText}</span>
    </div>
  );
}

export default TooltipImage;