import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import TooltipImage from './TooltipImage'; // Import the TooltipImage component
import './App.css';

// Register the required elements
ChartJS.register(ArcElement, Tooltip, Legend);

function App() {
  const [marketcaps, setMarketcaps] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const marketcapsResponse = await axios.get('./marketcaps.json');
        setMarketcaps(marketcapsResponse.data);
      } catch (error) {
        console.error('Error fetching marketcaps:', error);
      }
    }

    fetchData();
  }, []);

  function formatNumber(number) {
    return Math.round(number).toLocaleString();
  }

  const btcMemecoinsMarketCap = marketcaps.PUPSWORLDPEACE + marketcaps.BILLIONDOLLARCAT + marketcaps.DOGGOTOTHEMOON;
  const bitcoinMarketCap = marketcaps.Bitcoin;
  const btcMemecoinsPercentage = (btcMemecoinsMarketCap / bitcoinMarketCap) * 100;
  const btcRemainingMarketCapPercentage = 100 - btcMemecoinsPercentage;

  const ethMemecoinsMarketCap = marketcaps.shibainu + marketcaps.pepe + marketcaps.floki;
  const ethereumMarketCap = marketcaps.Ethereum;
  const ethMemecoinsPercentage = (ethMemecoinsMarketCap / ethereumMarketCap) * 100;
  const ethRemainingMarketCapPercentage = 100 - ethMemecoinsPercentage;

  const solMemecoinsMarketCap = marketcaps.dogwifhat + marketcaps.bonk + marketcaps.popcat;
  const solanaMarketCap = marketcaps.Solana;
  const solMemecoinsPercentage = (solMemecoinsMarketCap / solanaMarketCap) * 100;
  const solRemainingMarketCapPercentage = 100 - solMemecoinsPercentage;

  const data1 = {
    labels: ['Memecoins', 'Remaining Marketcap'],
    datasets: [{
      data: [btcMemecoinsPercentage, btcRemainingMarketCapPercentage],
      backgroundColor: ['#f39615', '#fbb867']
    }]
  };

  const data2 = {
    labels: ['Memecoins', 'Remaining Marketcap'],
    datasets: [{
      data: [ethMemecoinsPercentage, ethRemainingMarketCapPercentage],
      backgroundColor: ['#627eea', '#acb9ea']
    }]
  };

  const data3 = {
    labels: ['Memecoins', 'Remaining Marketcap'],
    datasets: [{
      data: [solMemecoinsPercentage, solRemainingMarketCapPercentage],
      backgroundColor: ['#9944fe', '#cca4fb']
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };

  const imageStyle = {
    width: '64px',
    height: '64px',
    margin: '0 5px'
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Top 3 Memecoins by Market Share</h1>
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <div style={{ width: '30%', textAlign: 'center' }}>
            <div>
              <a href="https://magiceden.us/runes/DOG%E2%80%A2GO%E2%80%A2TO%E2%80%A2THE%E2%80%A2MOON" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/37352/standard/DOGGOTOTHEMOON.png?1714096969" alt="img1" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.DOGGOTOTHEMOON)}`} />
              </a>
              <a href="https://magiceden.us/runes/PUPS%E2%80%A2WORLD%E2%80%A2PEACE" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/37159/standard/pups.jpeg?1713501045" alt="img2" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.PUPSWORLDPEACE)}`} />
              </a>
              <a href="https://magiceden.us/runes/BILLION%E2%80%A2DOLLAR%E2%80%A2CAT" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/38389/standard/IMG_2824.PNG?1728733256" alt="img3" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.BILLIONDOLLARCAT)}`} />
              </a>
            </div>
            <div>{btcMemecoinsPercentage.toFixed(2)}%</div>
            <Pie data={data1} options={options} key="chart1" />
            <div>BTC {btcRemainingMarketCapPercentage.toFixed(2)}%</div>
            <div className="market-cap">${formatNumber(bitcoinMarketCap)}</div>
          </div>
          <div style={{ width: '30%', textAlign: 'center' }}>
            <div>
              <a href="https://dexscreener.com/ethereum/0xcf6daab95c476106eca715d48de4b13287ffdeaa" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/11939/standard/shiba.png?1696511800" alt="img4" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.shibainu)}`} />
              </a>
              <a href="https://dexscreener.com/ethereum/0x11950d141ecb863f01007add7d1a342041227b58" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/29850/standard/pepe-token.jpeg?1696528776" alt="img5" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.pepe)}`} />
              </a>
              <a href="https://dexscreener.com/ethereum/0xca7c2771d248dcbe09eabe0ce57a62e18da178c0" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/16746/standard/PNG_image.png?1696516318" alt="img6" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.floki)}`} />
              </a>
            </div>
            <div>{ethMemecoinsPercentage.toFixed(2)}%</div>
            <Pie data={data2} options={options} key="chart2" />
            <div>ETH {ethRemainingMarketCapPercentage.toFixed(2)}%</div>
            <div className="market-cap">${formatNumber(ethereumMarketCap)}</div>
          </div>
          <div style={{ width: '30%', textAlign: 'center' }}>
            <div>
              <a href="https://dexscreener.com/solana/d6ndkrknqpmrzccng1gqxtf7mmohb7qr6gu5tkg59qz1" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/33566/standard/dogwifhat.jpg?1702499428" alt="img7" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.dogwifhat)}`} />
              </a>
              <a href="https://dexscreener.com/solana/6ofwm7kplfxnwmb3z5xwboxnspp3jjyirapqpsivcnsp" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/28600/standard/bonk.jpg?1696527587" alt="img8" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.bonk)}`} />
              </a>
              <a href="https://dexscreener.com/solana/ahttzwf3gmvmjdxwm8v2msxyjzj8rqr6hyac3g9477yj" target="_blank" rel="noopener noreferrer">
                <TooltipImage src="https://assets.coingecko.com/coins/images/33760/standard/image.jpg?1702964227" alt="img9" style={imageStyle} tooltipText={`$${formatNumber(marketcaps.popcat)}`} />
              </a>
            </div>
            <div>{solMemecoinsPercentage.toFixed(2)}%</div>
            <Pie data={data3} options={options} key="chart3" />
            <div>SOL {solRemainingMarketCapPercentage.toFixed(2)}%</div>
            <div className="market-cap">${formatNumber(solanaMarketCap)}</div>
          </div>
        </div>
        <p className="ordtext">Built by <a className="ordlink" href="https://x.com/OrdWatchers" target="_blank" rel="noopener noreferrer">OrdWatchers</a></p>
      </header>
    </div>
  );
}

export default App;